<template>
  <div class="questionnaire">
    <div class="banner">
      <img :src="require('@/assets/images/q-banner.png')" alt="">
    </div>
    <div v-if="question.status === 2" class="q-main">
      <div class="title"></div>
      <div class="des">{{question.intro}}</div>
      <div class="q-list">
        <el-form label-position="top" ref="ruleForms" :model="form">
          <el-form-item v-for="item in list" :key="item.id" :label="item.title"
          :prop="item.title" :rules="[{ required: item.isMust===1 ? true : false, message: '该项不能为空'}]">
            <el-input v-if="item.type==='input'" v-model="form[item.title]" />
            <el-input v-else-if="item.type==='textarea'"  type="textarea" v-model="form[item.title]" />
            <el-radio-group v-else-if="item.type==='radio'" v-model="form[item.title]">
              <el-radio v-for="child in item.values" :key="child.id" :label="child.label" :value="item.label"></el-radio>
            </el-radio-group>
            <el-checkbox-group v-else-if="item.type==='checkbox'" v-model="form[item.title]">
              <el-checkbox v-for="child in item.values" :key="child.id" :label="child.label" :value="item.label"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <div v-if="list.length > 0" class="btn-wrap">
          <van-button :style="{background: $store.state.themeInfo.themeColor, color: '#fff', borderRadius: '4px'  }" @click="handleSubmit">立即提交</van-button>
        </div>
      </div>
    </div>
    <div v-if="question.status === 1" class="q-stop">
      此问卷未发布
    </div>
    <div v-if="question.status === 3" class="q-stop">
      此问卷已停用
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  data() {
    return {
      question: {
        title: '',
        intro: '',
        status: ''
      },
      list: [],
      form: {
      },
      rules: {},
      sendlock: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      let result =  await this.$http.get(this.$baseURL.questionnaire.getAnswerList() + this.$route.params.id, 1)
      if(result.code === 200) {
        this.loading = false
        this.question.title = result.data.questions.title
        this.question.status = result.data.questions.status
        if (this.question.title) {
          document.title = this.question.title
        }
        this.question.intro = result.data.questions.intro
        const list = JSON.parse(result.data.questions.questions)
        if (result.data.answer.answerContent) {
          const form = JSON.parse(result.data.answer.answerContent)
          list.forEach((item) => {
            form.forEach(element => {
              if (item.id === element.id) {
                this.$set(this.form, item.title, element.answerValue)
              }
            })
          })
        } else {
          list.forEach((item) => {
            if (item.type==='checkbox') {
              this.$set(this.form, item.title, [])
            } else {
              this.$set(this.form, item.title, '')
            }
          })
        }
        this.list = list
      }
    },
    handleSubmit() {
        console.log(this.form)
     
    
      this.$refs['ruleForms'].validate(async (valid) => {
        console.log(valid)
        if (valid) {
          const list = []
          this.list.forEach(item => {
            for(let key in this.form) {
              if (key===item.title) {
                list.push({ id: item.id, answerValue: this.form[key] })
              }
            }
          })
          let result =  await this.$http.post(this.$baseURL.questionnaire.answerQuestions(), {
            id: this.$route.params.id,
            answerContent: JSON.stringify(list)
          }, 1)
          if (result.code === 200) {
            Toast('提交成功！')
            this.sendlock = false
          }
        } else {
          this.sendlock = false
          console.log('error submit!!');
          return false;
        }
      }).catch(() => {
         this.sendlock = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .questionnaire {
    font-size: 14px;
    .banner {
      img {
        width: 100%;
      }
    }
    .q-main {
      padding: 15px;
      .des {
        padding: 10px 0;
        font-size: 18px;
        border-bottom: 1px solid #dadada;
      }
      .q-list {
        padding: 15px 0;
        .btn-wrap {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  .q-stop {
    margin: 20px 0;
    text-align: center;
  }
  /deep/ .q-list {
    label {
      display: block;
      margin: 0 0 10px;
      line-height: 1;
    }
  }
  /deep/ .el-form-item__label{
    font-size: 15px;
    color: #000;
  }
</style>
